<template>
  <div class="container">
    <section class="time">
      <span>預約時間</span>
      <span>{{ caseData.appointmentTime }}</span>
      <span>進行日期</span>
      <span>{{ caseData.appointmentDate }}</span>
    </section>
    <progress-bar
      :type="caseData.type"
      :status="caseData.status"
      :dateList="caseData.dateList"
    ></progress-bar>
    <router-link
      v-if="caseData.status === 25"
      :to="{ name: 'casePayment', params: { id: $route.params.id } }"
      custom
      v-slot="{ navigate }"
    >
      <button class="payment-btn" @click="navigate">付款</button>
    </router-link>
    <button
      v-if="caseData.pickupNumber"
      class="take-drugs-btn"
      @click="showTakeDrugsNumber = true"
    >
      取藥號碼
    </button>
    <section class="data-container">
      <h1>病人資料</h1>
      <div class="grid-container">
        <div>姓名</div>
        <div>{{ caseData.name }}</div>
        <div>性別</div>
        <div>{{ genderInText }}</div>
        <div>年齡</div>
        <div>{{ caseData.age }}</div>
        <div>出生日期</div>
        <div>{{ caseData.birthday }}</div>
        <div>電話號碼</div>
        <div>{{ caseData.phone }}</div>
        <div>備注</div>
        <div>{{ caseData.remark }}</div>
        <div>附件</div>
        <div>
          <button
            v-for="(attachmentFolder, index) in caseData.attachmentList"
            :key="index"
            class="folder"
            @click="openFolder(attachmentFolder)"
          >
            <span>
              <i class="icon-folder"></i>
              {{ attachmentFolder.folder }}
            </span>
            <i class="icon-rightarrow"></i>
          </button>
        </div>
      </div>
    </section>
    <section
      class="data-container"
      v-if="caseData.relatedFilesList.length !== 0"
    >
      <h1>相關文件</h1>
      <div class="grid-container">
        <div
          class="two-cols-cell"
          v-for="(file, index) in caseData.relatedFilesList"
          :key="index"
        >
          <a
            @click="setDownLoad(file.fileUrl)"
            style="text-decoration: underline"
            target="_blank"
          >
            {{ file.fileName }}
          </a>
        </div>
      </div>
    </section>
    <section class="bill-data" v-if="caseData.status >= 25">
      <div class="title">
        <h1>賬單</h1>
        <router-link
          :to="{
            name: 'caseRefund',
            params: { id: $route.params.id },
          }"
          custom
          v-slot="{ href }"
          v-if="caseData.isRefundable === 1"
        >
          <a :href="href">申請退款</a>
        </router-link>
      </div>
      <div class="content">
        <!-- <a
          :href="'https://docs.google.com/viewer?embedded=true&url=' + caseData.bills "
          >{{ caseData.billsName }}</a
        > -->
        <a
          @click="setDownLoad(caseData.bills)"
          style="text-decoration: underline"
          >{{ caseData.billsName }}</a
        >
        <span class="set_order_btn" @click="showDetailPay"
          >賬單明細
          <img
            style="width: 10px; height: 10px"
            src="../../../assets/img/down_arrow.png"
          />
        </span>
      </div>
    </section>
    <!-- 跟进GlobalMedicare的改动，这部分内容不对病人进行显示 -->
    <section class="data-container">
      <h1>診症資料</h1>
      <div class="grid-container">
        <!-- <div>用戶症狀</div>
        <div>
          {{
            caseData.patientSymptoms ? caseData.patientSymptoms : "等待醫生填寫"
          }}
        </div>
        <div>醫生診斷</div>
        <div>
          {{
            caseData.doctorDiagnosis ? caseData.doctorDiagnosis : "等待醫生填寫"
          }}
        </div> -->
        <div>治療程序</div>
        <div>
          {{ caseData.treatmentPlan ? caseData.treatmentPlan : "等待醫生填寫" }}
        </div>
        <!-- <div>醫生附件</div>
        <div>
          <button
            v-for="(
              attachmentFolder, index
            ) in caseData.doctorReportAttachmentList"
            :key="index"
            class="folder"
            @click="openFolder(attachmentFolder)"
          >
            <span>
              <i class="icon-folder"></i>
              {{ attachmentFolder.folder }}
            </span>
            <i class="icon-rightarrow"></i>
          </button>
        </div>-->
      </div>
    </section>
    <div class="take-drugs-modal" v-if="showTakeDrugsNumber">
      <div class="title">取藥號碼</div>
      <div class="content">{{ caseData.pickupNumber }}</div>
      <div class="button-group">
        <!-- <button @click="showTakeDrugsNumber = false">取消</button> -->
        <button @click="confirmClick">確認</button>
      </div>
    </div>
    <div class="folder-page" :class="{ active: isFolderOpen }">
      <div class="back-container">
        <i class="icon-leftarrow"></i>
        <span @click="isFolderOpen = false">返回</span>
      </div>
      <div class="folder-title">
        <i class="icon-folder"></i>
        {{ activeFolder.folder }}
      </div>
      <span class="file" v-for="file in activeFolder.attachment" :key="file.id">
        <i class="icon-subdirectoryarrowright"></i>
        <a
          @click="openFile(file.url)"
          target="_blank"
          style="text-decoration: underline"
        >
          {{ file.title }}
        </a>
      </span>
    </div>

    <!-- 賬單明細提示  close-on-click-modal 禁止點擊其他地方-->
    <el-dialog
      title="賬單明細"
      :visible.sync="dialogVisible"
      width="85%"
      style="top: 18%"
      :close-on-click-modal="false"
    >
      <div class="set_row">
        <span class="set_more_big_tx">客戶賬單明細</span>
      </div>
      <div class="set_row" style="padding-top: 5px">
        <span class="set_more_tx">診金</span>
        <span class="set_more_tx set_marginleft"
          >+${{
            Number(caseData.consultationFee) + Number(caseData.medicineFee)
          }}</span
        >
      </div>
      <div class="set_row">
        <span class="set_more_tx">運費</span>
        <span class="set_more_tx set_marginleft"
          >+${{ Math.round(caseData.freightFee * 100) / 100 }}</span
        >
      </div>
      <div class="set_row">
        <span class="set_more_tx">支付手續費(第三方收取)</span>
        <span class="set_more_tx set_marginleft"
          >+${{ Math.round(caseData.transactionFee * 100) / 100 }}</span
        >
      </div>
      <div class="set_row">
        <span class="set_more_tx">預約費用</span>
        <span class="set_more_tx set_marginleft"
          >-${{ Math.round(caseData.bookingAmount * 100) / 100 }}</span
        >
      </div>
      <div class="set_row">
        <span class="set_more_tx">補貼</span>
        <span class="set_more_tx set_marginleft"
          >-${{ Math.round(caseData.fundingAmount * 100) / 100 }}</span
        >
      </div>
      <div class="set_row">
        <span class="set_more_tx">優惠金額</span>
        <span class="set_more_tx set_marginleft"
          >-${{ Math.round(caseData.promoCodeAmount * 100) / 100 }}</span
        >
      </div>

      <div class="set_row">
        <span class="set_more_tx">客戶賬單總額</span>
        <span class="set_more_tx set_marginleft"
          >$
          {{
            Math.round(
              (Number(caseData.consultationFee) +
                Number(caseData.medicineFee) +
                Number(caseData.freightFee) +
                Number(caseData.transactionFee) -
                Number(caseData.bookingAmount) -
                Number(caseData.fundingAmount) -
                Number(caseData.promoCodeAmount)) *
                100
            ) / 100
          }}</span
        >
      </div>

      <!-- <div class="set_row" style="margin-top: 20px; padding-bottom: 5px">
        <span class="set_more_big_tx">診所賬單明細</span>
      </div>
      <div class="set_row">
        <span class="set_more_tx">診所應收金額(iMeddy代收)</span>
        <span class="set_more_tx set_marginleft"
          >+${{ caseData.consultationFee }}</span
        >
      </div>
      <div class="set_row">
        <span class="set_more_tx">扣除平台使用費</span>
        <span class="set_more_tx set_marginleft"
          >-${{ caseData.platformUsageFee }}</span
        >
      </div>
      <div class="set_row">
        <span class="set_more_tx">診所實收金額</span>
        <span class="set_more_tx set_marginleft"
          >+${{ caseData.clinicIncomeAmount }}</span
        >
      </div> -->
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import progressBar from "../../../components/progressBar.vue";
import store from "@/store";
import { patientSign } from "@/api";
import Vue from "vue";
import axios from "axios";
export default {
  components: { progressBar },
  data: function () {
    return {
      showTakeDrugsNumber: false,
      activeFolder: {},
      isFolderOpen: false,
      dialogVisible: false,
    };
  },
  computed: {
    ...mapState({
      caseData: (state) => state.caseDetail.caseData,
    }),
    genderInText: function () {
      return this.caseData.gender === 1 ? "男" : "女";
    },
  },
  methods: {
    showDetailPay() {
      this.dialogVisible = true;
    },
    openFile(url) {
      if (url.indexOf(".pdf") >= 0) {
        this.$router.push({
          name: "orderDetails",
          query: {
            pdfUrl: url,
          },
        });
      } else if (
        url.indexOf(".doc") >= 0 ||
        url.indexOf(".docx") >= 0 ||
        url.indexOf(".xlsx") >= 0 ||
        url.indexOf(".xls") >= 0 ||
        url.indexOf(".ppt") >= 0 ||
        url.indexOf(".pptx") >= 0
      ) {
        window.open("https://view.officeapps.live.com/op/view.aspx?src=" + url);
      } else {
        window.open(url);
      }
    },
    openFolder(attachmentFolder) {
      // this.$refs.page.scrollTop = 0;
      this.activeFolder = attachmentFolder;
      this.isFolderOpen = true;
    },
    //設置賬單 顯示 到本地
    setDownLoad(url) {
      this.$router.push({
        name: "orderDetails",
        query: {
          pdfUrl: url,
        },
      });
    },
    //点击确认取号码
    confirmClick() {
      this.showTakeDrugsNumber = false;
      return;
      const url_ = process.env.VUE_APP_REQUEST_BASE_URL + "patient-cases/sign";
      Vue.axios
        .get(url_, {
          params: {
            access_token: store.state.user.token,
            id: this.$route.params.id,
          },
          headers: {
            "content-type": "application/json;charset=UTF-8",
          },
        })
        .then((res) => {
          console.log(res.data.code);
          if (res.data.code === 200) {
            this.$message(res.data.message);
            this.$router.push({ name: "index" });
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
          this.showTakeDrugsNumber = false;
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err.message,
          });
          return;
        });

      // const url_1 =
      //   "/dev-api/patient-cases/sign?access_token=" +
      //   store.state.user.token +
      //   "&id=" +
      //   this.$route.params.id;
      // store.state.user.token;
      // Vue.axios.get(url_1).then((res) => {
      //   console.log(res.data);
      //   if (res.data.code === 200) {
      //     location.reload();
      //     this.$message(res.data.message);
      //   }
      //   this.showTakeDrugsNumber = false;
      // });

      //设置请求取药接口进行完成
      let data1 = {
        access_token: store.state.user.token,
        id: this.$route.params.id,
      };
      // patientSign(data1)
      //   .then((res) => {
      //     console.log(res.data);
      //     if (res.data.code === 200) {
      //       // location.reload();
      //       this.$message(res.data.message);
      //       // this.$router.go(-1);
      //       this.$router.push({ name: "index" });
      //     }
      //   })
      //   .catch((err) => {
      //     this.$message({
      //       type: "error",
      //       message: err.message,
      //     });
      //     // this.$router.go(-1);
      //     // console.error(err);
      //   });
      this.showTakeDrugsNumber = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.set_order_btn {
  margin-left: 40px;
  padding: 2px;
  border-radius: 2px;
}
.set_marginleft {
  position: absolute;
  left: 75%;
}
.set_more_big_tx {
  font-size: 14px;
  font-weight: 600;
}
.set_more_tx {
  font-size: 13px;
}
.set_row {
  flex-direction: row;
  width: 100%;
  height: auto;
  position: relative;
  color: black;
}
.setbtn-check {
  margin-left: 70%;
  margin-top: 20px;
  width: 80px;
  height: 36px;
}
.time {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr 1fr;
  padding: 10px 16px 0;
  background-color: white;
  span {
    padding: 8px;
  }
  span:nth-of-type(1),
  span:nth-of-type(2) {
    border-bottom: 1px solid $black;
  }
}
a,
a:visited {
  font-size: 16px;
  color: $black;
}
.bill-data {
  @include flexc-start-stretch;
  .title {
    background-color: $green-300;
    color: white;
    @include flexr-between-center;
    padding: 16px;
    h1 {
      margin: 0;
      font-size: 21px;
      font-weight: $semi-bold;
    }
    a {
      color: white;
      font-size: 16px;
      font-weight: $regular;
      text-decoration: none;
    }
  }
  .content {
    background-color: white;
    padding: 16px;
    font-size: 16px;
    font-weight: $regular;
    a,
    a:visited {
      color: $black;
    }
  }
}
.payment-btn,
.take-drugs-btn {
  color: white;
  width: 100%;
  margin: 16px 0;
  font-size: 21px;
  font-weight: $semi-bold;
  padding: 16px;
  justify-content: flex-start;
}
.payment-btn {
  background-color: $red;
}
.take-drugs-btn {
  background-color: $green-300;
}
.take-drugs-modal {
  position: fixed;
  z-index: 600;
  width: 80vw;
  top: 30%;
  left: calc(50% - 40vw);
  padding: 16px;
  background-color: $green-100;
  .title {
    font-size: 21px;
    font-weight: $semi-bold;
    text-align: center;
    margin-bottom: 16px;
    color: white;
  }
  .content {
    padding: 8px;
    background-color: white;
    @include flexc-center-center;
    margin-bottom: 16px;
  }
  .button-group {
    @include flexr-center-stretch;
    button {
      padding: 8px;
      font-size: 16px;
      color: $black;
      flex-grow: 1;
    }
    button:nth-of-type(1) {
      margin-right: 4px;
    }
    button:nth-of-type(2) {
      margin-left: 4px;
    }
  }
}
.folder {
  width: 100%;
  background-color: transparent;
  color: $yellow-150;
  font-size: 16px;
  @include flexr-between-center;
}
.folder-page {
  z-index: 1000;
  @include open-from-right;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  word-break: break-all;
  &.active {
    transform: scaleX(1);
  }
  background-color: $white;
  @include flexc-start-stretch;
  padding: 0 16px 16px;
  .back-container {
    color: $green-300;
    font-size: 24px;
    font-weight: $semi-bold;
    background-color: transparent;
    padding: 16px 0;
    margin-bottom: 32px 0 16px;
  }
  .folder-title {
    font-size: 21px;
    color: $yellow-150;
  }
  .file,
  .file a,
  .file a:visited {
    font-size: 16px;
    color: $black;
  }
}
</style>