<template>
  <div class="swiper-container">
    <swiper class="step-list" ref="mySwiper" :options="swiperOptions">
      <swiper-slide
        class="step"
        v-for="(step, index) in activeStpes"
        :key="index"
        :class="activeClass(step.statusCode)"
      >
        <div class="step-container">
          <span>{{ step.name }}</span>
          <span class="step-date">{{ processingDate(step.date) }}</span>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
      validator: function (value) {
        return ["video", "text"].indexOf(value) != -1;
      },
    },
    dateList: Object,
  },
  computed: {
    activeStpes: function () {
      let type = this.type === "video" ? 1 : 2;
      return this.steps.filter((step) => {
        // 退款的情況下，要刪除送藥相關狀態
        if (
          this.dateList[33] &&
          (step.statusCode === 30 || step.statusCode === 35)
        )
          return false;
        // 如果不是退款，則要刪除退款中的狀態
        if (!this.dateList[33] && step.statusCode === 33) return false;
        // 默認的判斷
        return step.type === 0 || type == step.type;
      });
    },
    activeClass() {
      return (status) => {
        let arr = [];
        if (this.status >= status) arr.push("active");
        if (this.status === status) arr.push("isactive");
        return arr;
      };
    },
  },
  data: function () {
    return {
      swiperOptions: {
        slidesPerView: "auto",
        freeMode: true,
      },
      steps: [
        // type: 0 文字問診與視像問診都有
        // type: 1 只有視像問診有
        // type: 2 只有文字問診有
        {
          name: "預約中",
          statusCode: 0,
          date: this.dateList[0],
          type: 1,
        },
        {
          name: "成功預約",
          statusCode: 5,
          date: this.dateList[5],
          type: 1,
        },
        {
          name: "進入問診室",
          statusCode: 10,
          date: this.dateList[10],
          type: 1,
        },
        {
          name: "進行中",
          statusCode: 15,
          date: this.dateList[15],
          type: 2,
        },
        {
          name: "等待報告",
          statusCode: 20,
          date: this.dateList[20],
          type: 0,
        },
        {
          name: "等待付款",
          statusCode: 25,
          date: this.dateList[25],
          type: 0,
        },
        {
          name: "等待安排送藥",
          statusCode: 30,
          date: this.dateList[30],
          type: 0,
        },
        {
          name: "退款中",
          statusCode: 33,
          date: this.dateList[33],
          type: 0,
        },
        {
          name: "送藥中",
          statusCode: 35,
          date: this.dateList[35],
          type: 0,
        },
        {
          name: "已完成",
          statusCode: 40,
          date: this.dateList[40],
          type: 0,
        },
      ],
    };
  },
  methods: {
    //設置滾動條進行滾動到對應位置
    clickSwiper() {
      var getIndex = 0;
      switch (this.status) {
        case 0: {
          getIndex = 0;
          break;
        }
        case 5: {
          getIndex = 0;
          break;
        }
        case 10: {
          getIndex = 1;
          break;
        }
        case 15: {
          getIndex = 2;
          break;
        }
        case 20: {
          getIndex = 3;
          break;
        }
        case 25: {
          getIndex = 4;
          break;
        }
        case 30: {
          getIndex = 5;
          break;
        }
        case 35: {
          getIndex = 6;
          break;
        }
        case 40: {
          getIndex = 7;
          break;
        }
        default: {
          getIndex = 10;
          break;
        }
      }
      // console.log(getIndex);
      this.$refs.mySwiper.$swiper.slideTo(getIndex, 1000, true);
    },
    processingDate(date) {
      return date
        ? `${parseInt(date.split("-")[1])}月${parseInt(date.split("-")[2])}日`
        : "";
    },
  },
  //需要初始化之後才可以拿到該refs的東西
  updated() {
    this.clickSwiper();
  },
  watch: {
    dateList: function () {
      this.steps.forEach((e) => {
        e.date = this.dateList[e.statusCode];
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper-container {
  margin: 10px 0 30px;
}
.step-list {
  display: flex;
  height: 112px;
  padding: 56px 40px 0 40px;
  .step {
    flex: 1;
    height: 1px;
    background: #b1bed1;
    position: relative;
    min-width: 125px;
    .step-container {
      position: relative;
    }
    .step-date {
      font-size: 14px;
      top: calc(100% + 10px);
      left: 0;
      width: 66px;
      text-align: center;
      color: $dark-gray;
    }
    span {
      position: absolute;
      top: -40px;
      left: 0;
      transform: translateX(-40%);
      font-size: 20px;
      line-height: 30px;
      font-weight: $thin;
      color: $green-300;
    }
  }
  .step::before {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #b1bed1;
    position: absolute;
    top: -7px;
  }
  .step:last-of-type {
    min-width: 10px;
    flex: 0;
    white-space: nowrap;
    background: transparent;
  }
  .step.active {
    background: $green-300;
    .step-container {
      span {
        font-weight: $semi-bold;
      }
    }
  }
  .step.isactive {
    background-color: #b1bed1;
    span {
      font-weight: $semi-bold;
    }
  }
  .step.active::before {
    background: $green-300;
  }
}
</style>